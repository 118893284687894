import { Component, inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Button } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { PasswordModule } from 'primeng/password';
import { NgIf } from '@angular/common';
import { AuthService } from '../auth.service';
import { finalize, take, tap } from 'rxjs';
import { Router, RouterModule } from '@angular/router';

@Component({
    selector: 'app-login',
    standalone: true,
    imports: [ReactiveFormsModule, Button, InputTextModule, PasswordModule, NgIf, RouterModule],
    providers: [AuthService],
    templateUrl: './login.component.html',
    styleUrl: './login.component.scss',
})
export class LoginComponent {
    private router: Router = inject(Router);

    form: FormGroup<{ phone: FormControl<string>; password: FormControl<string>; otp: FormControl<string> }>;

    loginInProgress = false;

    shouldDisplayOtp = false;

    constructor(
        private readonly fb: FormBuilder,
        private readonly authService: AuthService,
    ) {
        this.form = this.fb.nonNullable.group({
            phone: ['', [Validators.required, Validators.pattern('^5[0-9]{8}$')]],
            password: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]],
            otp: ['', [Validators.minLength(6), Validators.maxLength(6)]],
        });
    }

    requestOtp() {
        this.loginInProgress = true;
        const { password } = this.form.getRawValue();
        const phone = `00966${this.form.controls.phone.value}`;
        this.authService
            .requestLoginOtp({ phone, password, otp_type: 'login' })
            .pipe(
                take(1),
                tap(() => {
                    this.shouldDisplayOtp = true;
                    this.form.controls.otp.addValidators(Validators.required);
                }),
                finalize(() => {
                    this.loginInProgress = false;
                }),
            )
            .subscribe();
    }

    validateOtp() {
        this.loginInProgress = true;
        const { password, otp } = this.form.getRawValue();
        const phone = `00966${this.form.controls.phone.value}`;
        this.authService
            .loginUser({ password }, otp, phone)
            .pipe(
                take(1),
                tap((response) => {
                    localStorage.setItem('accessToken', response.access_token);
                    localStorage.setItem('refreshToken', response.refresh_token);
                    this.router.navigate(['/providers']);
                }),
                finalize(() => {
                    this.loginInProgress = false;
                }),
            )
            .subscribe();
    }
}
