<section class="container">
    <div class="login-section">
        <div class="w-4 card py-8 rounded">
            <div class="text-center pb-4">
                <img src="./drahim_logo.svg" alt="Drahim Logo" />
            </div>
            <div class="text-center py-4">
                <h3 class="">Manual Investment Backoffice</h3>
            </div>
            <form [formGroup]="form">
                <!-- USER NAME -->
                <div class="flex justify-content-between my-2">
                    <label class="w-2 align-content-center" for="phone">Phone</label>
                    <input
                        placeholder="5XXXXXXXX"
                        class="w-10"
                        minlength="9"
                        maxlength="9"
                        formControlName="phone"
                        autocomplete="off"
                        pInputText
                        id="phone"
                    />
                </div>
                <!-- USER NAME -->
                <div class="flex justify-content-between my-4">
                    <label class="w-2 align-content-center" for="password">Password</label>
                    <p-password
                        [minlength]="6"
                        [maxLength]="6"
                        class="w-10"
                        inputStyleClass="w-full"
                        styleClass="w-full"
                        formControlName="password"
                        id="password"
                        autocomplete="off"
                        type="password"
                        [feedback]="false"
                    />
                </div>
                <div *ngIf="shouldDisplayOtp" class="flex justify-content-between my-4">
                    <label class="w-2 align-content-center" for="otp">OTP</label>
                    <input placeholder="XXXXXX" class="w-10" formControlName="otp" pInputText id="otp" />
                </div>
                <div class="w-full flex justify-content-center my-4">
                    <p-button
                        *ngIf="!shouldDisplayOtp"
                        (onClick)="requestOtp()"
                        [disabled]="form.invalid || loginInProgress"
                        type="submit"
                        label="Request Login OTP"
                        class="w-6"
                        styleClass="w-full"
                        [icon]="loginInProgress ? 'pi pi-spin pi-spinner' : ''"
                    >
                    </p-button>
                    <p-button
                        (onClick)="validateOtp()"
                        *ngIf="shouldDisplayOtp"
                        [disabled]="form.invalid || loginInProgress"
                        type="submit"
                        label="Login"
                        class="w-6"
                        styleClass="w-full"
                        [icon]="loginInProgress ? 'pi pi-spin pi-spinner' : ''"
                    >
                    </p-button>
                </div>
            </form>
        </div>
    </div>
</section>
