import { Routes } from '@angular/router';
import { LoginComponent } from './auth/login/login.component';
import { AuthGuard } from './shared/guards/auth.guard';
import { inject } from '@angular/core';

export const routes: Routes = [
    {
        path: 'providers',
        loadChildren: () => import('./providers/providers.module').then((m) => m.ProvidersModule),
        canActivate: [() => inject(AuthGuard).canActivate()],
    },
    {
        path: 'core',
        loadChildren: () => import('./core/core.module').then((m) => m.CoreModule),
        canActivate: [() => inject(AuthGuard).canActivate()],
    },
    {
        path: 'auth',
        component: LoginComponent,
    },
    { path: '**', redirectTo: 'providers' },
];
