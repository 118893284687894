<ng-container *ngIf="authService.isLoggedIn | async; else authRouter">
    <div class="layout-container" [ngClass]="containerClass">
        <app-sidebar></app-sidebar>
        <div class="layout-content-wrapper">
            <p-toast></p-toast>
            <app-topbar></app-topbar>
            <app-breadcrumb class="content-breadcrumb"></app-breadcrumb>
            <div class="layout-content">
                <router-outlet></router-outlet>
            </div>
        </div>
        <app-profilemenu></app-profilemenu>
        <div class="layout-mask"></div>
    </div>
</ng-container>
<ng-template #authRouter>
    <p-toast></p-toast>
    <router-outlet></router-outlet>
</ng-template>
