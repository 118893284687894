import { HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { inject } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { ToastService } from '../toast/toast.service';
import { Router } from '@angular/router';
const isRefreshing = new BehaviorSubject<boolean>(false);
const refreshTokenSubject = new BehaviorSubject<string | undefined>(undefined);

export const tokenInterceptor: HttpInterceptorFn = (request: HttpRequest<unknown>, next: HttpHandlerFn) => {
    const authService = inject(AuthService);
    const accessToken = localStorage.getItem('accessToken');
    const refreshToken = localStorage.getItem('refreshToken');
    const toastService = inject(ToastService);
    const router: Router = inject(Router);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    let r = request.clone();
    if (r.url.includes('/admin/auth/refresh') && refreshToken) {
        r = addToken(r, refreshToken);
    } else if (accessToken) {
        if (authService.isJwtExpired(accessToken, 30)) {
            return handleTokenExpired(r, next, authService, toastService, router);
        }
        r = addToken(r, localStorage.getItem('accessToken')!);
    }

    return next(r).pipe(
        catchError((err: HttpErrorResponse) => {
            if (err.status != 401) {
                toastService.showErrorViaToast(toastService.formateErrorMessage(err));
            }
            if (err.status === 401 && refreshToken) {
                if (!isRefreshing.value) {
                    return handleTokenExpired(request, next, authService, toastService, router);
                } else {
                    return refreshTokenSubject.pipe(
                        filter((result) => result !== undefined),
                        take(1),
                        switchMap(() => next(addToken(r, localStorage.getItem('accessToken') || ''))),
                    );
                }
            }
            return throwError(err);
        }),
    );
};

const addToken = (request: HttpRequest<unknown>, token: string): HttpRequest<unknown> => {
    return request.clone({
        setHeaders: {
            Authorization: `Bearer ${token}`,
        },
    });
};

const handleTokenExpired = (
    request: HttpRequest<unknown>,
    next: HttpHandlerFn,
    authService: AuthService,
    toastService: ToastService,
    router: Router,
): Observable<HttpEvent<unknown>> => {
    const refreshToken = localStorage.getItem('refreshToken');
    if (!refreshToken) {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        router.navigate(['/auth']).then();
        return throwError(() => new Error('No refresh token available'));
    }

    isRefreshing.next(true);
    refreshTokenSubject.next(undefined);
    return authService.refreshToken(refreshToken).pipe(
        switchMap(() => {
            isRefreshing.next(false);
            refreshTokenSubject.next(localStorage.getItem('refreshToken')!);
            const newRequest = addToken(request, localStorage.getItem('accessToken')!);
            return next(newRequest);
        }),
        catchError((err: HttpErrorResponse) => {
            isRefreshing.next(false);
            localStorage.removeItem('accessToken');
            localStorage.removeItem('refreshToken');
            toastService.showErrorViaToast(toastService.formateErrorMessage(err));
            return throwError('Refresh token failed');
        }),
    );
};
